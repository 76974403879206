import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(private http: HttpClient) {}
  getSurvey(flag) {
    return new Promise((resolve, reject) => {
      const url = environment.evmUrl + '/surveys/start-survey/' + flag;
      this.http
        .get(url)
        .toPromise()
        .then((res: any) => {
          console.log(res);
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }
  finishSurvey(data) {
    return new Promise((resolve, reject) => {
      const url = environment.evmUrl + '/surveys/finished';
      this.http
        .post(url, data)
        .toPromise()
        .then((response) => {
          console.log(response);
          resolve(response);
        });
    });
  }
  getLocation(ofi) {
    return new Promise((resolve, reject) => {
      const url = environment.evmUrl + '/location-office/' + ofi;
      this.http
        .get(url)
        .toPromise()
        .then((res: any) => {
          console.log(res);
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }
  getCitys(codigo) {
    return new Promise((resolve, reject) => {
      const url = environment.evmUrl + '/citys/' + codigo;
      this.http
        .get(url)
        .toPromise()
        .then((res: any) => {
          console.log(res);
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }
  getCountryByRegion(codigo) {
    return new Promise((resolve, reject) => {
      const url = environment.evmUrl + '/country-for-region/' + codigo;
      this.http
        .get(url)
        .toPromise()
        .then((res: any) => {
          console.log(res);
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }
}
