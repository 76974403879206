<div class="page">
    <div class="langs">
        <div class="flag" (click)="changeFlag('ES')">
            <img src="https://upload.wikimedia.org/wikipedia/commons/8/89/Bandera_de_Espa%C3%B1a.svg" alt="">
        </div>
        <div class="flag" (click)="changeFlag('EN')">
            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Flag_of_the_United_Kingdom_%283-5%29.svg/1200px-Flag_of_the_United_Kingdom_%283-5%29.svg.png"
                alt="">
        </div>
    </div>
    <mat-card *ngFor="let currentAsk of asks; index as i">
        <mat-card-header>
            <mat-card-title *ngIf="currentAsk.type == 'titulo'">
                <h2 class="title">
                    {{currentAsk.name}}
                </h2>
            </mat-card-title>
            <mat-card-subtitle *ngIf=" currentAsk.type == 'subtitulo'">
                <h4 class="subtitle">
                    {{currentAsk.name}}
                </h4>
            </mat-card-subtitle>
            <mat-card-subtitle *ngIf=" currentAsk.type == 'subtituloEntidades'">
                <h4 class="subtitle">
                    <span *ngIf="condition1 == true || condition2 == true || condition3 == true">
                        {{currentAsk.name}}
                        s
                    </span>
                </h4>

            </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content
            *ngIf="currentAsk.type == 'hijos' || currentAsk.type == 'hijos11' || currentAsk.type == 'hijos18'">

            <mat-form-field class="example-full-width" *ngIf="currentAsk.show == true">
                <mat-label>{{currentAsk.name}}</mat-label>
                <input type="number" min="0" (change)="updateHijos(i, $event)" [(ngModel)]="currentAsk.value" matInput>
            </mat-form-field>

        </mat-card-content>
        <mat-card-content *ngIf="currentAsk.type == 'numero' ">
            <mat-form-field class="example-full-width">
                <mat-label>{{currentAsk.name}}</mat-label>
                <input type="number" min="0" (change)="updateText(i, $event)" [(ngModel)]="currentAsk.value" matInput>
            </mat-form-field>
        </mat-card-content>
        <mat-card-content *ngIf="currentAsk.type == 'escala'">
            <mat-label>{{currentAsk.name}}</mat-label>
            <div class="info" *ngIf="currentAsk.type == 'escala' && lang == 'ES'">
                Indica tu grado de acuerdo con el siguiente enunciado. Para ello utilice una escala de 0 a 10,
                donde 0 es nada de acuerdo y 10 totalmente de acuerdo.
            </div>
            <div class="info" *ngIf="currentAsk.type == 'escala' && lang == 'EN' ">
                Choose your sentiment in response to the following statement. Use a scale of 0 to 10 for this, with 0
                representing nothing in agreement and 10 representing complete agreement.
            </div>
            <div class="bar-slide">
                <div class="item" [ngClass]="{'active':currentAsk.value == 1}" (click)="update(i, {value:1})">
                    <p>1</p>
                </div>
                <div class="item" [ngClass]="{'active':currentAsk.value == 2}" (click)="update(i, {value:2})">
                    <p>2</p>
                </div>
                <div class="item" [ngClass]="{'active':currentAsk.value == 3}" (click)="update(i, {value:3})">
                    <p>3</p>
                </div>
                <div class="item" [ngClass]="{'active':currentAsk.value == 4}" (click)="update(i, {value:4})">
                    <p>4</p>
                </div>
                <div class="item" [ngClass]="{'active':currentAsk.value == 5}" (click)="update(i, {value:5})">
                    <p>5</p>
                </div>
                <div class="item" [ngClass]="{'active':currentAsk.value == 6}" (click)="update(i, {value:6})">
                    <p>6</p>
                </div>
                <div class="item" [ngClass]="{'active':currentAsk.value == 7}" (click)="update(i, {value:7})">
                    <p>7</p>
                </div>
                <div class="item" [ngClass]="{'active':currentAsk.value == 8}" (click)="update(i, {value:8})">
                    <p>8</p>
                </div>
                <div class="item" [ngClass]="{'active':currentAsk.value == 9}" (click)="update(i, {value:9})">
                    <p>9</p>
                </div>
                <div class="item" [ngClass]="{'active':currentAsk.value == 10}" (click)="update(i, {value:10})">
                    <p>10</p>
                </div>
                <div class="item" [ngClass]="{'active':currentAsk.value == null}" (click)="update(i, {value:null})">
                    <p>N/A</p>
                </div>
            </div>

        </mat-card-content>
        <mat-card-content *ngIf="currentAsk.type == 'escala-2'">
            <mat-label>{{currentAsk.name}}</mat-label>
            <div class="info" *ngIf="currentAsk.type == 'escala' && lang == 'ES'">

            </div>
            <div class="info" *ngIf="currentAsk.type == 'escala' && lang == 'EN' ">

            </div>
            <div class="bar-slide">
                <div class="item" [ngClass]="{'active':currentAsk.value == 1}" (click)="update(i, {value:1})">
                    <p>1</p>
                </div>
                <div class="item" [ngClass]="{'active':currentAsk.value == 2}" (click)="update(i, {value:2})">
                    <p>2</p>
                </div>
                <div class="item" [ngClass]="{'active':currentAsk.value == 3}" (click)="update(i, {value:3})">
                    <p>3</p>
                </div>
                <div class="item" [ngClass]="{'active':currentAsk.value == 4}" (click)="update(i, {value:4})">
                    <p>4</p>
                </div>
                <div class="item" [ngClass]="{'active':currentAsk.value == 5}" (click)="update(i, {value:5})">
                    <p>5</p>
                </div>
                <div class="item" [ngClass]="{'active':currentAsk.value == 6}" (click)="update(i, {value:6})">
                    <p>6</p>
                </div>
                <div class="item" [ngClass]="{'active':currentAsk.value == 7}" (click)="update(i, {value:7})">
                    <p>7</p>
                </div>
                <div class="item" [ngClass]="{'active':currentAsk.value == 8}" (click)="update(i, {value:8})">
                    <p>8</p>
                </div>
                <div class="item" [ngClass]="{'active':currentAsk.value == 9}" (click)="update(i, {value:9})">
                    <p>9</p>
                </div>
                <div class="item" [ngClass]="{'active':currentAsk.value == 10}" (click)="update(i, {value:10})">
                    <p>10</p>
                </div>
                <div class="item" [ngClass]="{'active':currentAsk.value == null}" (click)="update(i, {value:null})">
                    <p>N/A</p>
                </div>
            </div>

        </mat-card-content>
        <mat-card-content *ngIf="currentAsk.type == 'escala-i'">
            <mat-label>{{currentAsk.name}}</mat-label>
            <div class="info" *ngIf="currentAsk.type == 'escala-i' && lang == 'ES' ">
                Indica tu grado de acuerdo con el siguiente enunciado. Para ello utilice una escala de 1 a 10,
                donde 1 es nada de acuerdo y 10 totalmente de acuerdo.
            </div>
            <div class="info" *ngIf="currentAsk.type == 'escala-i' && lang == 'EN' ">
                Select your feeling in accordance with the statement next.
                For it use a scale of 1 to 10, where 0 is nothing in accordance and 10 is totally agree
            </div>
            <div class="bar-slide">
                <div class="item" [ngClass]="{'active':currentAsk.value == 10}" (click)="update(i, {value:10})">
                    <p>1</p>
                </div>
                <div class="item" [ngClass]="{'active':currentAsk.value == 9}" (click)="update(i, {value:9})">
                    <p>2</p>
                </div>
                <div class="item" [ngClass]="{'active':currentAsk.value == 8}" (click)="update(i, {value:8})">
                    <p>3</p>
                </div>
                <div class="item" [ngClass]="{'active':currentAsk.value == 7}" (click)="update(i, {value:7})">
                    <p>4</p>
                </div>
                <div class="item" [ngClass]="{'active':currentAsk.value == 6}" (click)="update(i, {value:6})">
                    <p>5</p>
                </div>
                <div class="item" [ngClass]="{'active':currentAsk.value == 5}" (click)="update(i, {value:5})">
                    <p>6</p>
                </div>
                <div class="item" [ngClass]="{'active':currentAsk.value == 4}" (click)="update(i, {value:4})">
                    <p>7</p>
                </div>
                <div class="item" [ngClass]="{'active':currentAsk.value == 3}" (click)="update(i, {value:3})">
                    <p>8</p>
                </div>
                <div class="item" [ngClass]="{'active':currentAsk.value == 2}" (click)="update(i, {value:2})">
                    <p>9</p>
                </div>
                <div class="item" [ngClass]="{'active':currentAsk.value == 1}" (click)="update(i, {value:1})">
                    <p>10</p>
                </div>
                <div class="item" [ngClass]="{'active':currentAsk.value == null}" (click)="update(i, {value:null})">
                    <p>N/A</p>
                </div>
            </div>
            <!-- <mat-slider [max]="10" [min]="0" [step]="1" [value]="currentAsk.value" [tickInterval]="1"
                [(ngModel)]="currentAsk.value" (change)="update(i, $event)" [thumbLabel]="true" id="p41">
            </mat-slider> -->
        </mat-card-content>
        <mat-card-content *ngIf="currentAsk.type == 'escala-5'">
            <mat-label>{{currentAsk.name}}</mat-label>
            <div class="info" *ngIf="currentAsk.type == 'escala-5' && lang == 'ES' ">
                Indica tu grado de acuerdo con el siguientes enunciado. Para ello utilice una escala de 1 a 5,
                (1 Prácticamente nunca -5 Prácticamente siempre)
            </div>
            <div class="info" *ngIf="currentAsk.type == 'escala-5' && lang == 'EN' ">
                Select your feeling in accordance with the statement next.
                For it use a scale of 1 to 5, (where 1 is Practically never and 5 is Practically always)
            </div>

            <div class="bar-slide">
                <div class="item" [ngClass]="{'active':currentAsk.value == 1}" (click)="update(i, {value:1})">
                    <p>1</p>
                </div>
                <div class="item" [ngClass]="{'active':currentAsk.value == 2}" (click)="update(i, {value:2})">
                    <p>2</p>
                </div>
                <div class="item" [ngClass]="{'active':currentAsk.value == 3}" (click)="update(i, {value:3})">
                    <p>3</p>
                </div>
                <div class="item" [ngClass]="{'active':currentAsk.value == 4}" (click)="update(i, {value:4})">
                    <p>4</p>
                </div>
                <div class="item" [ngClass]="{'active':currentAsk.value == 5}" (click)="update(i, {value:5})">
                    <p>5</p>
                </div>
            </div>
            <!-- <mat-slider [max]="10" [min]="0" [step]="1" [value]="currentAsk.value" [tickInterval]="1"
                [(ngModel)]="currentAsk.value" (change)="update(i, $event)" [thumbLabel]="true" id="p41">
            </mat-slider> -->
        </mat-card-content>
        <mat-card-content *ngIf="currentAsk.type == 'escala-05'">
            <mat-label>{{currentAsk.name}}</mat-label>
            <div class="info" *ngIf="currentAsk.type == 'escala-05' && lang == 'ES' ">
                Indica tu grado de acuerdo con el siguiente enunciado. Para ello utilice una escala de 0 a 5,
                (0 Nunca- 5 Siempre)
            </div>
            <div class="info" *ngIf="currentAsk.type == 'escala-05' && lang == 'EN' ">
                Select your feeling in accordance with the statement next.
                For it use a scale of 0 to 5, (where 0 is never and 5 is always)
            </div>
            <div class="bar-slide">
                <div class="item" [ngClass]="{'active':currentAsk.value == 0}" (click)="update(i, {value:1})">
                    <p>0</p>
                </div>
                <div class="item" [ngClass]="{'active':currentAsk.value == 1}" (click)="update(i, {value:1})">
                    <p>1</p>
                </div>
                <div class="item" [ngClass]="{'active':currentAsk.value == 2}" (click)="update(i, {value:2})">
                    <p>2</p>
                </div>
                <div class="item" [ngClass]="{'active':currentAsk.value == 3}" (click)="update(i, {value:3})">
                    <p>3</p>
                </div>
                <div class="item" [ngClass]="{'active':currentAsk.value == 4}" (click)="update(i, {value:4})">
                    <p>4</p>
                </div>
                <div class="item" [ngClass]="{'active':currentAsk.value == 5}" (click)="update(i, {value:5})">
                    <p>5</p>
                </div>
            </div>
            <!-- <mat-slider [max]="10" [min]="0" [step]="1" [value]="currentAsk.value" [tickInterval]="1"
                [(ngModel)]="currentAsk.value" (change)="update(i, $event)" [thumbLabel]="true" id="p41">
            </mat-slider> -->
        </mat-card-content>
        <mat-card-content *ngIf="currentAsk.type == 'trueFalse'">
            <mat-slide-toggle (change)="updateT(i, $event)" [(ngModel)]="currentAsk.value" [value]="currentAsk.value">
                {{currentAsk.name}}
            </mat-slide-toggle>
        </mat-card-content>
        <mat-card-content *ngIf="currentAsk.type == 'trueFalseHijos'">
            <mat-slide-toggle (change)="updateTHijos(i, $event)" [(ngModel)]="currentAsk.value"
                [value]="currentAsk.value">
                {{currentAsk.name}}
            </mat-slide-toggle>
        </mat-card-content>
        <mat-card-content *ngIf="currentAsk.type == 'selectEntidades'">
            <mat-form-field class="example-full-width" *ngIf="condition1 == true">
                <mat-label>{{currentAsk.name}}</mat-label>
                <mat-select (selectionChange)="update(i, $event)" [(ngModel)]="currentAsk.value">
                    <mat-option value='0'>
                        Pub
                    </mat-option>
                    <mat-option value='1'>
                        Concert
                    </mat-option>
                    <mat-option value='2'>
                        Priv
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </mat-card-content>
        <mat-card-content *ngIf="currentAsk.type == 'selectEntidades11'">
            <mat-form-field class="example-full-width" *ngIf="condition2 == true">
                <mat-label>{{currentAsk.name}}</mat-label>
                <mat-select (selectionChange)="update(i, $event)" [(ngModel)]="currentAsk.value">
                    <mat-option value='0'>
                        Pub
                    </mat-option>
                    <mat-option value='1'>
                        Concert
                    </mat-option>
                    <mat-option value='2'>
                        Priv
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </mat-card-content>
        <mat-card-content *ngIf="currentAsk.type == 'selectEntidades18'">
            <mat-form-field class="example-full-width" *ngIf="condition3 == true">
                <mat-label>{{currentAsk.name}}</mat-label>
                <mat-select (selectionChange)="update(i, $event)" [(ngModel)]="currentAsk.value">
                    <mat-option value='1'>
                        Pub
                    </mat-option>
                    <mat-option value='2'>
                        Concert
                    </mat-option>
                    <mat-option value='3'>
                        Priv
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </mat-card-content>
        <mat-card-content *ngIf="currentAsk.type == 'civilState' && lang == 'ES'">
            <mat-form-field class="example-full-width">
                <!-- <mat-label>{{currentAsk.name}}</mat-label> -->
                <mat-select (selectionChange)="update(i, $event)" [(ngModel)]="currentAsk.value">
                    <mat-option value='0'>
                        Casado / en pareja…
                    </mat-option>
                    <mat-option value='1'>
                        Divorciado / Separado
                    </mat-option>
                    <mat-option value='2'>
                        Viudo /a
                    </mat-option>
                    <mat-option value='3'>
                        Soltero/a
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </mat-card-content>

        <mat-card-content *ngIf="currentAsk.type == 'civilState' && lang == 'EN'">
            <mat-form-field class="example-full-width">
                <!-- <mat-label>{{currentAsk.name}}</mat-label> -->
                <mat-select (selectionChange)="update(i, $event)" [(ngModel)]="currentAsk.value">
                    <mat-option value='0'>
                        Married / In a relationship
                    </mat-option>
                    <mat-option value='1'>
                        Divorced / Separated
                    </mat-option>
                    <mat-option value='2'>
                        Widow / Widower
                    </mat-option>
                    <mat-option value='3'>
                        Single
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </mat-card-content>

        <mat-card-content *ngIf="currentAsk.type == 'textarea'">
            <mat-form-field class="example-full-width ">
                <mat-label>{{currentAsk.name}}</mat-label>
                <br>
                <br>
                <textarea [(ngModel)]="currentAsk.value" (change)="updateText( i , $event)" class="area"
                    matInput>{{currentAsk.value}}</textarea>
            </mat-form-field>
        </mat-card-content>
        <mat-card-content *ngIf="currentAsk.type == 'textareaDemografico' && condition4 == true">
            <mat-form-field class="example-full-width ">
                <mat-label>{{currentAsk.name}}</mat-label>
                <br>
                <br>
                <textarea [(ngModel)]="currentAsk.value" (change)="updateText( i , $event)" class="area"
                    matInput>{{currentAsk.value}}</textarea>
            </mat-form-field>
        </mat-card-content>
        <mat-card-content *ngIf="currentAsk.type == 'sexo' && lang == 'ES'">
            <mat-form-field class="example-full-width">
                <mat-label>Selecciona tu sexo</mat-label>
                <mat-select (selectionChange)="update(i, $event)" [(ngModel)]="currentAsk.value">
                    <mat-option value='1'>
                        Hombre
                    </mat-option>
                    <mat-option value='2'>
                        Mujer
                    </mat-option>
                </mat-select>
            </mat-form-field>

        </mat-card-content>

        <mat-card-content *ngIf="currentAsk.type == 'sexo' && lang == 'EN' ">
            <mat-form-field class="example-full-width">
                <mat-label>Selet your gender</mat-label>
                <mat-select (selectionChange)="update(i, $event)" [(ngModel)]="currentAsk.value">
                    <mat-option value='1'>
                        Female
                    </mat-option>
                    <mat-option value='2'>
                        Male
                    </mat-option>
                </mat-select>
            </mat-form-field>

        </mat-card-content>

        <mat-card-content *ngIf="currentAsk.type == 'selectEdad' && lang == 'ES'">
            <mat-form-field class="example-full-width">
                <mat-label>Selecciona tu tramo de edad</mat-label>
                <mat-select (selectionChange)="update(i, $event)" [(ngModel)]="currentAsk.value">
                    <mat-option value='1'>
                        < 25 </mat-option>
                            <mat-option value='2'>
                                25-35
                            </mat-option>
                            <mat-option value='3'>
                                36-45
                            </mat-option>
                            <mat-option value='4'>
                                46-55
                            </mat-option>
                            <mat-option value='5'>
                                > 56
                            </mat-option>
                </mat-select>
            </mat-form-field>

        </mat-card-content>

        <mat-card-content *ngIf="currentAsk.type == 'selectEdad' && lang == 'EN'">
            <mat-form-field class="example-full-width">
                <mat-label>Select your age group</mat-label>
                <mat-select (selectionChange)="update(i, $event)" [(ngModel)]="currentAsk.value">
                    <mat-option value='1'>
                        < 25 </mat-option>
                            <mat-option value='2'>
                                25-35
                            </mat-option>
                            <mat-option value='3'>
                                36-45
                            </mat-option>
                            <mat-option value='4'>
                                46-55
                            </mat-option>
                            <mat-option value='5'>
                                > 56
                            </mat-option>
                </mat-select>
            </mat-form-field>

        </mat-card-content>

        <mat-card-content *ngIf="currentAsk.type == 'selectAntiguedad' && lang == 'ES'">
            <mat-form-field class="example-full-width">
                <mat-label>Selecciona tu Antiguedad</mat-label>
                <mat-select (selectionChange)="update(i, $event)" [(ngModel)]="currentAsk.value">
                    <mat-option value='1'>
                        < 2 años </mat-option>
                            <mat-option value='2'>
                                3-5 años

                            </mat-option>
                            <mat-option value='3'>
                                6-10 años

                            </mat-option>
                            <mat-option value='4'>
                                11-15 años

                            </mat-option>
                            <mat-option value='5'>
                                >15 años

                            </mat-option>
                </mat-select>
            </mat-form-field>

        </mat-card-content>

        <mat-card-content *ngIf="currentAsk.type == 'selectAntiguedad' && lang == 'EN'">
            <mat-form-field class="example-full-width">
                <mat-label>Select your senority</mat-label>
                <mat-select (selectionChange)="update(i, $event)" [(ngModel)]="currentAsk.value">
                    <mat-option value='1'>
                        < 2 años </mat-option>
                            <mat-option value='2'>
                                3-5 años

                            </mat-option>
                            <mat-option value='3'>
                                6-10 años

                            </mat-option>
                            <mat-option value='4'>
                                11-15 años

                            </mat-option>
                            <mat-option value='5'>
                                >15 años

                            </mat-option>
                </mat-select>
            </mat-form-field>

        </mat-card-content>

        <mat-card-content *ngIf="currentAsk.type == 'optionFacil'">
            <mat-label>{{currentAsk.name}}</mat-label><br>
            <mat-radio-group aria-label="Select an option" [(ngModel)]="currentAsk.value" (change)="update(i, $event)">
                <mat-radio-button value="5">Extremadamente fácil </mat-radio-button> <br>
                <mat-radio-button value="4">Muy fácil</mat-radio-button><br>
                <mat-radio-button value="3">Algo fácil </mat-radio-button><br>
                <mat-radio-button value="2  ">No tan fácil </mat-radio-button><br>
                <mat-radio-button value="1">Nada fácil
                </mat-radio-button>
            </mat-radio-group>
        </mat-card-content>
        <mat-card-content *ngIf="currentAsk.type == 'optionDisponible'">
            <mat-label>{{currentAsk.name}}</mat-label><br>

            <mat-radio-group aria-label="Select an option" [(ngModel)]="currentAsk.value" (change)="update(i, $event)">
                <mat-radio-button value="5">Extremadamente disponible</mat-radio-button> <br>
                <mat-radio-button value="4">Muy disponible</mat-radio-button><br>
                <mat-radio-button value="3">Algo disponible </mat-radio-button><br>
                <mat-radio-button value="2"> No tan disponible </mat-radio-button><br>
                <mat-radio-button value="1"> Nada disponible
                </mat-radio-button>
            </mat-radio-group>
        </mat-card-content>
        <mat-card-content *ngIf="currentAsk.type == 'optionFrecuencia'">
            <mat-label>{{currentAsk.name}}</mat-label><br>

            <mat-radio-group aria-label="Select an option" [(ngModel)]="currentAsk.value" (change)="update(i, $event)">
                <mat-radio-button value="5">Con extremada frecuencia</mat-radio-button> <br>
                <mat-radio-button value="4">Con mucha frecuencia</mat-radio-button><br>
                <mat-radio-button value="3">Con alguna frecuencia</mat-radio-button><br>
                <mat-radio-button value="2">No con tanta frecuencia</mat-radio-button><br>
                <mat-radio-button value="1">Con ninguna frecuencia
                </mat-radio-button>
            </mat-radio-group>
        </mat-card-content>
        <mat-card-content *ngIf="currentAsk.type == 'optionCantidad'">
            <mat-label>{{currentAsk.name}}</mat-label><br>

            <mat-radio-group aria-label="Select an option" [(ngModel)]="currentAsk.value" (change)="update(i, $event)">
                <mat-radio-button value="5">Mejora bastante</mat-radio-button> <br>
                <mat-radio-button value="4">Mejora mucho</mat-radio-button><br>
                <mat-radio-button value="3">Mejora algo</mat-radio-button><br>
                <mat-radio-button value="2">Mejora un poco</mat-radio-button><br>
                <mat-radio-button value="1">No mejora en absoluto</mat-radio-button>
            </mat-radio-group>
        </mat-card-content>
        <mat-card-content *ngIf="currentAsk.type == 'optionEfectividad'">
            <mat-label>{{currentAsk.name}}</mat-label><br>

            <mat-radio-group aria-label="Select an option" [(ngModel)]="currentAsk.value" (change)="update(i, $event)">
                <mat-radio-button value="5">Extremadamente efectivo</mat-radio-button> <br>
                <mat-radio-button value="4">Muy efectivo</mat-radio-button><br>
                <mat-radio-button value="3">Moderadamente efectivo </mat-radio-button><br>
                <mat-radio-button value="2"> Poco efectivo </mat-radio-button><br>
                <mat-radio-button value="1">Nada efectivo</mat-radio-button>
            </mat-radio-group>
        </mat-card-content>
        <mat-card-content *ngIf="currentAsk.type == 'optionConsistencia'">
            <mat-label>{{currentAsk.name}}</mat-label><br>
            <mat-radio-group aria-label="Select an option" [(ngModel)]="currentAsk.value" (change)="update(i, $event)">
                <mat-radio-button value="5">Extremadamente consistente </mat-radio-button> <br>
                <mat-radio-button value="4">Muy consistente</mat-radio-button><br>
                <mat-radio-button value="3">Moderadamente consistente</mat-radio-button><br>
                <mat-radio-button value="2">Poco consistente</mat-radio-button><br>
                <mat-radio-button value="1">Nada consistente</mat-radio-button>
            </mat-radio-group>
        </mat-card-content>
        <mat-card-content *ngIf="currentAsk.type == 'optionRazon'">
            <mat-label>{{currentAsk.name}}</mat-label><br>

            <mat-radio-group aria-label="Select an option" [(ngModel)]="currentAsk.value" (change)="update(i, $event)">
                <mat-radio-button value="5">Extremadamente razonables </mat-radio-button> <br>
                <mat-radio-button value="4">Muy razonables</mat-radio-button><br>
                <mat-radio-button value="3">Moderadamente razonables</mat-radio-button><br>
                <mat-radio-button value="2">Poco razonables</mat-radio-button><br>
                <mat-radio-button value="1">Nada razonables</mat-radio-button>
            </mat-radio-group>
        </mat-card-content>
        <mat-card-content *ngIf="currentAsk.type == 'optionGroup'">
            <mat-label>{{currentAsk.name}}</mat-label><br>

            <mat-radio-group aria-label="Select an option" [(ngModel)]="currentAsk.value" (change)="update(i, $event)">
                <mat-radio-button value="5">Demasiado </mat-radio-button> <br>
                <mat-radio-button value="4">Mucho</mat-radio-button><br>
                <mat-radio-button value="3">Lo apropiado</mat-radio-button><br>
                <mat-radio-button value="2">Muy poco</mat-radio-button><br>
                <mat-radio-button value="1">Demasiado poco</mat-radio-button>
            </mat-radio-group>
        </mat-card-content>
        <mat-card-content *ngIf="currentAsk.type == 'optionTiempo'">
            <mat-label>{{currentAsk.name}}</mat-label><br>

            <mat-radio-group aria-label="Select an option" [(ngModel)]="currentAsk.value" (change)="update(i, $event)">
                <mat-radio-button value="5">Siempre </mat-radio-button> <br>
                <mat-radio-button value="4">La mayor parte del tiempo</mat-radio-button><br>
                <mat-radio-button value="3">Cerca de la mitad del tiempo</mat-radio-button><br>
                <mat-radio-button value="2">De vez en cuando</mat-radio-button><br>
                <mat-radio-button value="1">Nunca</mat-radio-button>
            </mat-radio-group>
        </mat-card-content>
        <mat-card-content *ngIf="currentAsk.type == 'optionConfianza'">
            <mat-label>{{currentAsk.name}}</mat-label><br>
            <!-- Extremadamente digno/a de confianza - Muy digno/a de confianza - Algo digno/a de confianza - No tan digno/a de confianza - Nada digno/a de confianza -->

            <mat-radio-group aria-label="Select an option" [(ngModel)]="currentAsk.value" (change)="update(i, $event)">
                <mat-radio-button value="5">Extremadamente digno/a de confianza</mat-radio-button> <br>
                <mat-radio-button value="4">Muy digno/a de confianza</mat-radio-button><br>
                <mat-radio-button value="3">Algo digno/a de confianza</mat-radio-button><br>
                <mat-radio-button value="2">No tan digno/a de confianza</mat-radio-button><br>
                <mat-radio-button value="1">Nada digno/a de confianza</mat-radio-button>
            </mat-radio-group>
        </mat-card-content>
        <mat-card-content *ngIf="currentAsk.type == 'optionEficaz'">
            <mat-label>{{currentAsk.name}}</mat-label><br>
            <!-- Mur eficazmente - Bastante eficazmente - Algo eficazmente - No tan eficazmente - Nada eficazmente -->

            <mat-radio-group aria-label="Select an option" [(ngModel)]="currentAsk.value" (change)="update(i, $event)">
                <mat-radio-button value="5">Muy eficazmente</mat-radio-button> <br>
                <mat-radio-button value="4">Bastante eficazmente </mat-radio-button><br>
                <mat-radio-button value="3">Algo eficazmente</mat-radio-button><br>
                <mat-radio-button value="2">No tan eficazmente</mat-radio-button><br>
                <mat-radio-button value="1">Nada eficazmente</mat-radio-button>
            </mat-radio-group>
        </mat-card-content>
        <mat-card-content *ngIf="currentAsk.type == 'optionProfesional'">
            <mat-label>{{currentAsk.name}}</mat-label><br>
            <!-- Extremadamente profesional - Mur profesional - Algo profesional - No muy profesional - Nada profesional -->


            <mat-radio-group aria-label="Select an option" [(ngModel)]="currentAsk.value" (change)="update(i, $event)">
                <mat-radio-button value="5"> Extremadamente profesional</mat-radio-button> <br>
                <mat-radio-button value="4">Muy profesional </mat-radio-button><br>
                <mat-radio-button value="3">Algo profesionale</mat-radio-button><br>
                <mat-radio-button value="2">No muy profesional</mat-radio-button><br>
                <mat-radio-button value="1">Nada profesional</mat-radio-button>
            </mat-radio-group>
        </mat-card-content>
        <mat-card-content *ngIf="currentAsk.type == 'optionSatisfecho'">
            <mat-label>{{currentAsk.name}}</mat-label><br>
            <!-- Muy satisfecho /a - Satisfecho/a - Ni satisfecho/a ni insatisfecho/a - Insatisfecho/a - Muy insatisfecho/a -->



            <mat-radio-group aria-label="Select an option" [(ngModel)]="currentAsk.value" (change)="update(i, $event)">
                <mat-radio-button value="5"> Muy satisfecho /a</mat-radio-button> <br>
                <mat-radio-button value="4">Satisfecho/a</mat-radio-button><br>
                <mat-radio-button value="3">Ni satisfecho/a ni insatisfecho/a</mat-radio-button><br>
                <mat-radio-button value="2">Insatisfecho/a</mat-radio-button><br>
                <mat-radio-button value="1">Muy insatisfecho/a</mat-radio-button>
            </mat-radio-group>
        </mat-card-content>
        <mat-card-content *ngIf="currentAsk.type == 'optionEficiente'">
            <mat-label>{{currentAsk.name}}</mat-label><br>
            <!-- Extremadamente eficiente - Muy eficiente - Moderadamente eficiente - Poco eficiente - Nada eficiente -->
            <mat-radio-group aria-label="Select an option" [(ngModel)]="currentAsk.value" (change)="update(i, $event)">
                <mat-radio-button value="5">Extremadamente eficiente</mat-radio-button> <br>
                <mat-radio-button value="4">Muy eficiente </mat-radio-button><br>
                <mat-radio-button value="3">Moderadamente eficiente</mat-radio-button><br>
                <mat-radio-button value="2">Poco eficiente</mat-radio-button><br>
                <mat-radio-button value="1">Nada eficiente</mat-radio-button>
            </mat-radio-group>
        </mat-card-content>
        <mat-card-content *ngIf="currentAsk.type == 'optionBuena'">
            <mat-label>{{currentAsk.name}}</mat-label><br>
            <!-- Extremadamente buena – Muy buena – Algo Buena – No tan buena – Nada buena -->
            <mat-radio-group aria-label="Select an option" [(ngModel)]="currentAsk.value" (change)="update(i, $event)">
                <mat-radio-button value="5">Extremadamente buena</mat-radio-button> <br>
                <mat-radio-button value="4">Muy buena </mat-radio-button><br>
                <mat-radio-button value="3">Algo Buena</mat-radio-button><br>
                <mat-radio-button value="2">No tan buena </mat-radio-button><br>
                <mat-radio-button value="1">Nada buena</mat-radio-button>
            </mat-radio-group>
        </mat-card-content>
        <mat-card-content *ngIf="currentAsk.type == 'optionProductivo'">
            <mat-label>{{currentAsk.name}}</mat-label><br>
            <!-- Extremadamente productivo/a – Muy productivo/a – Algo productivo/a – No tan productivo/a – Nada productivo/a -->

            <mat-radio-group aria-label="Select an option" [(ngModel)]="currentAsk.value" (change)="update(i, $event)">
                <mat-radio-button value="5">Extremadamente productivo/a</mat-radio-button> <br>
                <mat-radio-button value="4">Muy productivo/a </mat-radio-button><br>
                <mat-radio-button value="3">Algo productivo/a</mat-radio-button><br>
                <mat-radio-button value="2">No tan productivo/a </mat-radio-button><br>
                <mat-radio-button value="1">Nada productivo/a</mat-radio-button>
            </mat-radio-group>
        </mat-card-content>
        <mat-card-content *ngIf="currentAsk.type == 'optionBien'">
            <mat-label>{{currentAsk.name}}</mat-label><br>
            <!-- Extremadamente bien – Muy bien – Algo Buena – No tan bien – Nada bien -->


            <mat-radio-group aria-label="Select an option" [(ngModel)]="currentAsk.value" (change)="update(i, $event)">
                <mat-radio-button value="5">Extremadamente bien</mat-radio-button> <br>
                <mat-radio-button value="4">Muy bien </mat-radio-button><br>
                <mat-radio-button value="3"> Algo Buena</mat-radio-button><br>
                <mat-radio-button value="2">No tan bien </mat-radio-button><br>
                <mat-radio-button value="1">Nada bien</mat-radio-button>
            </mat-radio-group>
        </mat-card-content>
        <mat-card-content *ngIf="currentAsk.type == 'optionProactivo'">
            <mat-label>{{currentAsk.name}}</mat-label><br>
            <!-- Extremadamente proactivo – Muy proactivo – Algo proactivo – No tan proactivo – Nada proactivo -->
            <mat-radio-group aria-label="Select an option" [(ngModel)]="currentAsk.value" (change)="update(i, $event)">
                <mat-radio-button value="5">Extremadamente proactivo</mat-radio-button> <br>
                <mat-radio-button value="4">Muy proactivo </mat-radio-button><br>
                <mat-radio-button value="3"> Algo proactivo</mat-radio-button><br>
                <mat-radio-button value="2">No tan proactivo </mat-radio-button><br>
                <mat-radio-button value="1">Nada proactivo</mat-radio-button>
            </mat-radio-group>
        </mat-card-content>
        <mat-card-content
            *ngIf="currentAsk.type == 'optionFrecuenciaTiempo' || currentAsk.type == 'optionFrecuenciaTempo'">
            <mat-label>{{currentAsk.name}}</mat-label><br>
            <!-- Siempre - La mayor parte del tiempo - Cerca de la mitad del tiempo - De vez en cuando - Nunca -->
            <mat-radio-group aria-label="Select an option" [(ngModel)]="currentAsk.value" (change)="update(i, $event)">
                <mat-radio-button value="5">Siempre</mat-radio-button> <br>
                <mat-radio-button value="4">La mayor parte del tiempo </mat-radio-button><br>
                <mat-radio-button value="3">Cerca de la mitad del tiempo</mat-radio-button><br>
                <mat-radio-button value="2"> De vez en cuando</mat-radio-button><br>
                <mat-radio-button value="1">Nunca</mat-radio-button>
            </mat-radio-group>
        </mat-card-content>
        <mat-card-content *ngIf="currentAsk.type == 'optionMayor'">
            <mat-label>{{currentAsk.name}}</mat-label><br>
            <!-- Mucho mayor - Mayor - Casi igual - Menos - Mucho menor -->
            <mat-radio-group aria-label="Select an option" [(ngModel)]="currentAsk.value" (change)="update(i, $event)">
                <mat-radio-button value="5">Mucho mayor</mat-radio-button> <br>
                <mat-radio-button value="4">Mayor</mat-radio-button><br>
                <mat-radio-button value="3">Casi igual</mat-radio-button><br>
                <mat-radio-button value="2">Menos</mat-radio-button><br>
                <mat-radio-button value="1">Mucho menor</mat-radio-button>
            </mat-radio-group>
        </mat-card-content>
        <mat-card-content *ngIf="currentAsk.type == 'optionAtento'">
            <mat-label>{{currentAsk.name}}</mat-label><br>
            <!-- Muy atento/a - Bastante atento/a - Moderadamente atento/a - Un poco atento/a - Nada atento/a -->
            <mat-radio-group aria-label="Select an option" [(ngModel)]="currentAsk.value" (change)="update(i, $event)">
                <mat-radio-button value="5">Muy atento/a</mat-radio-button> <br>
                <mat-radio-button value="4">Bastante atento/a</mat-radio-button><br>
                <mat-radio-button value="3">Moderadamente atento/a</mat-radio-button><br>
                <mat-radio-button value="2">Un poco atento/a</mat-radio-button><br>
                <mat-radio-button value="1">Nada atento/a</mat-radio-button>
            </mat-radio-group>
        </mat-card-content>
        <mat-card-content *ngIf="currentAsk.type == 'optionDispuesto'">
            <mat-label>{{currentAsk.name}}</mat-label><br>
            <!-- Extremadamente dispuesto/a - Muy dispuesto/a - algo dispuesto/a - No tan dispuesto/a - Nada dispuesto/a -->
            <mat-radio-group aria-label="Select an option" [(ngModel)]="currentAsk.value" (change)="update(i, $event)">
                <mat-radio-button value="5">Extremadamente dispuesto/a</mat-radio-button> <br>
                <mat-radio-button value="4">Muy dispuesto/a</mat-radio-button><br>
                <mat-radio-button value="3">Algo dispuesto/a</mat-radio-button><br>
                <mat-radio-button value="2">No tan dispuesto/a</mat-radio-button><br>
                <mat-radio-button value="1">Nada dispuesto/a</mat-radio-button>
            </mat-radio-group>
        </mat-card-content>
        <mat-card-content *ngIf="currentAsk.type == 'optionRapido'">
            <mat-label>{{currentAsk.name}}</mat-label><br>
            <!-- Extremadamente rapido - Muy rapido - Algo rapido - No tan rapido - Nada rapido -->

            <mat-radio-group aria-label="Select an option" [(ngModel)]="currentAsk.value" (change)="update(i, $event)">
                <mat-radio-button value="5">Extremadamente rapido</mat-radio-button> <br>
                <mat-radio-button value="4">Muy rapido</mat-radio-button><br>
                <mat-radio-button value="3">Algo rapido</mat-radio-button><br>
                <mat-radio-button value="2">No tan rapido</mat-radio-button><br>
                <mat-radio-button value="1">Nada rapido</mat-radio-button>
            </mat-radio-group>
        </mat-card-content>
        <mat-card-content *ngIf="currentAsk.type == 'optionTrabajo'">
            <mat-label>{{currentAsk.name}}</mat-label><br>
            <!-- Extremadamente trabajador/a - Muy trabajador/a - Algo trabajador/a - No tan trabajador/a - Nada trabajador/a -->
            <mat-radio-group aria-label="Select an option" [(ngModel)]="currentAsk.value" (change)="update(i, $event)">
                <mat-radio-button value="5">Extremadamente trabajador/a</mat-radio-button> <br>
                <mat-radio-button value="4">Muy trabajador/a</mat-radio-button><br>
                <mat-radio-button value="3">Algo trabajador/a</mat-radio-button><br>
                <mat-radio-button value="2">No tan trabajador/a</mat-radio-button><br>
                <mat-radio-button value="1">Nada trabajador/a</mat-radio-button>
            </mat-radio-group>
        </mat-card-content>
        <mat-card-content *ngIf="currentAsk.type == 'optionRespeto'">
            <mat-label>{{currentAsk.name}}</mat-label><br>
            <!-- Enormemente respetuoso/a - Muy respetuoso/a - Algo respetuoso/a - No tan respetuoso/a - Nada respetuoso/a -->

            <mat-radio-group aria-label="Select an option" [(ngModel)]="currentAsk.value" (change)="update(i, $event)">
                <mat-radio-button value="5">Enormemente respetuoso/a</mat-radio-button> <br>
                <mat-radio-button value="4">Muy respetuoso/a</mat-radio-button><br>
                <mat-radio-button value="3">Algo respetuoso/a</mat-radio-button><br>
                <mat-radio-button value="2">No tan respetuoso/a</mat-radio-button><br>
                <mat-radio-button value="1">Nada respetuoso/a</mat-radio-button>
            </mat-radio-group>
        </mat-card-content>
        <mat-card-content *ngIf="currentAsk.type == 'optionPositivo'">
            <mat-label>{{currentAsk.name}}</mat-label><br>
            <!-- Muy positivo - Positivo - Ni positivo ni negativo - Negativo - Muy negativo -->


            <mat-radio-group aria-label="Select an option" [(ngModel)]="currentAsk.value" (change)="update(i, $event)">
                <mat-radio-button value="5">Muy positivo</mat-radio-button> <br>
                <mat-radio-button value="4">Positivo</mat-radio-button><br>
                <mat-radio-button value="3">Ni positivo ni negativo </mat-radio-button><br>
                <mat-radio-button value="2">Negativo</mat-radio-button><br>
                <mat-radio-button value="1"> Muy negativo</mat-radio-button>
            </mat-radio-group>
        </mat-card-content>

        <mat-card-content *ngIf="currentAsk.type == 'test-49'  && lang == 'ES'">
            <mat-label>{{currentAsk.name}}</mat-label><br>

            <mat-radio-group aria-label="Select an option" [(ngModel)]="currentAsk.value" (change)="update(i, $event)">
                <mat-radio-button value="A">Los equipos deben gozar de autonomía para desarrollar su creatividad al
                    máximo.
                </mat-radio-button> <br>
                <mat-radio-button value="B">Los líderes deben fortalecer las habilidades de su equipo.
                </mat-radio-button><br>
                <mat-radio-button value="C">La diversidad debe ser parte de la cultura de una empresa.
                </mat-radio-button><br>
                <mat-radio-button value="D"> La optimización de los procesos permite tomar decisiones más rápidas.
                </mat-radio-button><br>
                <mat-radio-button value="E"> Las jerarquías rígidas deben reemplazarse por estructuras más flexibles.
                </mat-radio-button> <br>
                <mat-radio-button value="F">Las personas son lo más importante para la transformación de una empresa.
                </mat-radio-button>
            </mat-radio-group>
        </mat-card-content>

        <mat-card-content *ngIf="currentAsk.type == 'test-49' && lang == 'EN'">
            <mat-label>{{currentAsk.name}}</mat-label><br>

            <mat-radio-group aria-label="Select an option" [(ngModel)]="currentAsk.value" (change)="update(i, $event)">
                <mat-radio-button value="A">Teams must have autonomy to fully develop their creativity.
                </mat-radio-button> <br>
                <mat-radio-button value="B">Leaders must strengthen the team's skills.
                </mat-radio-button><br>
                <mat-radio-button value="C">Diversity must be part of a company's culture.
                </mat-radio-button><br>
                <mat-radio-button value="D">Processes' optimization allows you to make faster decisions.
                </mat-radio-button><br>
                <mat-radio-button value="E">Rigid hierarchies must be replaced with more flexible structures.
                </mat-radio-button> <br>
                <mat-radio-button value="F">People are the most important thing for a company's transformation.
                </mat-radio-button>
            </mat-radio-group>
        </mat-card-content>

        <mat-card-content *ngIf="currentAsk.type == 'test-50'  && lang == 'ES'">
            <mat-label>{{currentAsk.name}}</mat-label><br>

            <mat-radio-group aria-label="Select an option" [(ngModel)]="currentAsk.value" (change)="update(i, $event)">
                <mat-radio-button value="A">Confianza y productividad.
                </mat-radio-button> <br>
                <mat-radio-button value="B">Un skilling continuo.
                </mat-radio-button><br>
                <mat-radio-button value="C">Conciencia cultural y diversidad.
                </mat-radio-button><br>
                <mat-radio-button value="D"> Agilidad y adaptación.
                </mat-radio-button><br>
                <mat-radio-button value="E">Pensamiento crítico e innovación.

                </mat-radio-button> <br>
                <mat-radio-button value="F">Crecimiento y creatividad.

                </mat-radio-button>
            </mat-radio-group>
        </mat-card-content>

        <mat-card-content *ngIf="currentAsk.type == 'test-50'  && lang == 'EN'">
            <mat-label>{{currentAsk.name}}</mat-label><br>

            <mat-radio-group aria-label="Select an option" [(ngModel)]="currentAsk.value" (change)="update(i, $event)">
                <mat-radio-button value="A">Trust and productivity
                </mat-radio-button> <br>
                <mat-radio-button value="B">Continuous improvement of skills
                </mat-radio-button><br>
                <mat-radio-button value="C">Cultural awareness and diversity
                </mat-radio-button><br>
                <mat-radio-button value="D">Agility and adaptability
                </mat-radio-button><br>
                <mat-radio-button value="E">Critical thinking and innovation
                </mat-radio-button> <br>
                <mat-radio-button value="F">Growth and creativity

                </mat-radio-button>
            </mat-radio-group>
        </mat-card-content>

        <mat-card-content *ngIf="currentAsk.type == 'test-51'  && lang == 'ES'">
            <mat-label>{{currentAsk.name}}</mat-label><br>

            <mat-radio-group aria-label="Select an option" [(ngModel)]="currentAsk.value" (change)="update(i, $event)">
                <mat-radio-button value="A">Confiar en tu equipo.

                </mat-radio-button> <br>
                <mat-radio-button value="B">Los desafíos.

                </mat-radio-button><br>
                <mat-radio-button value="C">Las diferencias.

                </mat-radio-button><br>
                <mat-radio-button value="D"> Lo incierto.

                </mat-radio-button><br>
                <mat-radio-button value="E">Los cambios.


                </mat-radio-button> <br>
                <mat-radio-button value="F">Afrontar riesgos.


                </mat-radio-button>
            </mat-radio-group>
        </mat-card-content>

        <mat-card-content *ngIf="currentAsk.type == 'test-51'  && lang == 'EN'">
            <mat-label>{{currentAsk.name}}</mat-label><br>

            <mat-radio-group aria-label="Select an option" [(ngModel)]="currentAsk.value" (change)="update(i, $event)">
                <mat-radio-button value="A">Trusting your team.

                </mat-radio-button> <br>
                <mat-radio-button value="B">Challenges.

                </mat-radio-button><br>
                <mat-radio-button value="C"> Differences.

                </mat-radio-button><br>
                <mat-radio-button value="D">Uncertainty.

                </mat-radio-button><br>
                <mat-radio-button value="E">Changes.


                </mat-radio-button> <br>
                <mat-radio-button value="F">Confronting risks.


                </mat-radio-button>
            </mat-radio-group>
        </mat-card-content>

        <mat-card-content *ngIf="currentAsk.type == 'test-52' && lang == 'ES'">
            <mat-label>{{currentAsk.name}}</mat-label><br>

            <mat-radio-group aria-label="Select an option" [(ngModel)]="currentAsk.value" (change)="update(i, $event)">
                <mat-radio-button value="A">Barack Obama.
                </mat-radio-button> <br>
                <mat-radio-button value="B">Reina Isabel II.
                </mat-radio-button><br>
                <mat-radio-button value="C">Oprah Winfrey.
                </mat-radio-button><br>
                <mat-radio-button value="D"> Mahatma Gandhi.
                </mat-radio-button><br>
                <mat-radio-button value="E">Steve Jobs.
                </mat-radio-button> <br>
                <mat-radio-button value="F">Angela Merkel.
                </mat-radio-button>
            </mat-radio-group>
        </mat-card-content>

        <mat-card-content *ngIf="currentAsk.type == 'test-52' && lang == 'EN'">
            <mat-label>{{currentAsk.name}}</mat-label><br>

            <mat-radio-group aria-label="Select an option" [(ngModel)]="currentAsk.value" (change)="update(i, $event)">
                <mat-radio-button value="A">Barack Obama.
                </mat-radio-button> <br>
                <mat-radio-button value="B">Queen Isabel II.
                </mat-radio-button><br>
                <mat-radio-button value="C">Oprah Winfrey.
                </mat-radio-button><br>
                <mat-radio-button value="D"> Mahatma Gandhi.
                </mat-radio-button><br>
                <mat-radio-button value="E">Steve Jobs.
                </mat-radio-button> <br>
                <mat-radio-button value="F">Angela Merkel.
                </mat-radio-button>
            </mat-radio-group>
        </mat-card-content>

        <mat-card-content *ngIf="currentAsk.type == 'test-53' && lang == 'ES'">
            <mat-label>{{currentAsk.name}}</mat-label><br>

            <mat-radio-group aria-label="Select an option" [(ngModel)]="currentAsk.value" (change)="update(i, $event)">
                <mat-radio-button value="A">“Hay una diferencia entre ser líder y ser un jefe. Los dos se basan en la
                    autoridad. Un jefe demanda obediencia ciega; un líder se gana su autoridad a través de conocimiento
                    y confianza”. <br> –Klaus Balkenhol, campeón olímpico en equitación.

                </mat-radio-button> <br>
                <mat-radio-button value="B">“El liderazgo es desbloquear el potencial de la gente para hacerlos
                    mejores”. –Bill Bradley, campeón de la NBA con los New York Knicks y exsenador de los Estados
                    Unidos.

                </mat-radio-button><br>
                <mat-radio-button value="C">“Este mundo no va a cambiar a menos que estemos dispuestos a cambiar
                    nosotros mismos”. –Rigoberta Menchú, embajadora de buena voluntad de la UNESCO y <br> Premio Nobel
                    de la Paz en 1992.

                </mat-radio-button><br>
                <mat-radio-button value="D"> “El pesimista se queja del viento. El optimista espera que cambie. El líder
                    arregla las velas”. –John Maxwell, escritor y conferencista.

                </mat-radio-button><br>
                <mat-radio-button value="E">“Nunca se debe gatear cuando se tiene el impulso de volar” –Helen Keller,
                    escritora y activista política.

                </mat-radio-button> <br>
                <mat-radio-button value="F">“He aprendido a arriesgarme, a hacer cosas nuevas. El crecimiento y la
                    comodidad no pueden coexistir”. –Virginia Rometty, expresidenta y CEO de IBM.

                </mat-radio-button>
            </mat-radio-group>
        </mat-card-content>

        <mat-card-content *ngIf="currentAsk.type == 'test-53' && lang == 'EN' ">
            <mat-label>{{currentAsk.name}}</mat-label><br>

            <mat-radio-group aria-label="Select an option" [(ngModel)]="currentAsk.value" (change)="update(i, $event)">
                <mat-radio-button value="A">“There is a difference between being a leader and being a boss. Both are
                    based on authority. A boss demands blind obedience; a leader earns his authority through
                    understanding and trust.”
                    <br>-Klaus Balkenhol, equestrian and Olympic champion.

                </mat-radio-button> <br>
                <mat-radio-button value="B">“Leadership is unlocking people's potential to become better.”
                    <br> –Bill Bradley, American politician and former NBA player.

                </mat-radio-button><br>
                <mat-radio-button value="C">“This world's not going to change unless we're willing to change
                    ourselves.”.
                    <br>–Rigoberta Menchú, UNESCO's goodwill ambassador and Nobel Peace Prize laureate.

                </mat-radio-button><br>
                <mat-radio-button value="D">“The pessimist complains about the wind. The optimist expects it to change.
                    The leader adjusts the sails”.
                    <br>–John Maxwell, authorand speaker.

                </mat-radio-button><br>
                <mat-radio-button value="E">“One can never consent to creep when one feels an impulse to soar.”
                    <br> –Helen Keller, author and political activist.

                </mat-radio-button> <br>
                <mat-radio-button value="F">“I learned to always take on things I'd never done before. Growth and
                    comfort do not coexist.”.
                    <br>–Virginia Rometty, ex-president and IBM's CEO.

                </mat-radio-button>
            </mat-radio-group>
        </mat-card-content>

        <mat-card-content *ngIf="currentAsk.type == 'test-54' && lang == 'ES'">
            <mat-label>{{currentAsk.name}}</mat-label><br>

            <mat-radio-group aria-label="Select an option" [(ngModel)]="currentAsk.value" (change)="update(i, $event)">
                <mat-radio-button value="A">Twitter.
                </mat-radio-button> <br>
                <mat-radio-button value="B">LinkedIn y Twitter.
                </mat-radio-button><br>
                <mat-radio-button value="C">Facebook, Instagram y Twitter.
                </mat-radio-button><br>
                <mat-radio-button value="D">LinkedIn (aunque prefiero las herramientas colaborativas, como Slack o
                    Trello).
                </mat-radio-button><br>
                <mat-radio-button value="E">A veces uso Twitter.
                </mat-radio-button> <br>
                <mat-radio-button value="F">LinkedIn.
                </mat-radio-button>
            </mat-radio-group>
        </mat-card-content>

        <mat-card-content *ngIf="currentAsk.type == 'test-54' && lang == 'EN'">
            <mat-label>{{currentAsk.name}}</mat-label><br>

            <mat-radio-group aria-label="Select an option" [(ngModel)]="currentAsk.value" (change)="update(i, $event)">
                <mat-radio-button value="A">Twitter.
                </mat-radio-button> <br>
                <mat-radio-button value="B">LinkedIn y Twitter.
                </mat-radio-button><br>
                <mat-radio-button value="C">Facebook, Instagram y Twitter.
                </mat-radio-button><br>
                <mat-radio-button value="D">LinkedIn (although I'd rather use collaborative tools such as Slack or
                    Trello).
                </mat-radio-button><br>
                <mat-radio-button value="E">Sometimes I use Twitter.
                </mat-radio-button> <br>
                <mat-radio-button value="F">LinkedIn.
                </mat-radio-button>
            </mat-radio-group>
        </mat-card-content>

        <mat-card-content *ngIf="currentAsk.type == 'test-55' && lang == 'ES'">
            <mat-label>{{currentAsk.name}}</mat-label><br>

            <mat-radio-group aria-label="Select an option" [(ngModel)]="currentAsk.value" (change)="update(i, $event)">
                <mat-radio-button value="A">Para estar al tanto de las tendencias y seguir a otros/otras líderes.

                </mat-radio-button> <br>
                <mat-radio-button value="B">Para intercambiar opiniones, compartir conocimiento y aprender de las
                    experiencias de los demás.

                </mat-radio-button><br>
                <mat-radio-button value="C">Para estar informado/a de lo que ocurre en el ámbito empresarial y el mundo.

                </mat-radio-button><br>
                <mat-radio-button value="D">Para estar al tanto de las últimas novedades y cambios en el mundo de los
                    negocios.

                </mat-radio-button><br>
                <mat-radio-button value="E"> Uso muy poco las redes sociales, solo para asuntos puntuales relacionados a
                    la compañía.

                </mat-radio-button> <br>
                <mat-radio-button value="F">Para enterarme de las últimas noticias del mundo empresarial y laboral.

                </mat-radio-button>
            </mat-radio-group>
        </mat-card-content>

        <mat-card-content *ngIf="currentAsk.type == 'test-55' && lang == 'EN'">
            <mat-label>{{currentAsk.name}}</mat-label><br>

            <mat-radio-group aria-label="Select an option" [(ngModel)]="currentAsk.value" (change)="update(i, $event)">
                <mat-radio-button value="A">To keep up with trends and follow other leaders.

                </mat-radio-button> <br>
                <mat-radio-button value="B">To exchange opinions, share knowledge and learn from other people's
                    experiences.

                </mat-radio-button><br>
                <mat-radio-button value="C">To be informed about what goes on in the business field and around the
                    world.

                </mat-radio-button><br>
                <mat-radio-button value="D">To keep up to date with the latest news and changes in the business world.

                </mat-radio-button><br>
                <mat-radio-button value="E">I don't usually use social media, just for exceptional company matters.

                </mat-radio-button> <br>
                <mat-radio-button value="F">To find out the latest news from the business and employment world.

                </mat-radio-button>
            </mat-radio-group>
        </mat-card-content>

        <mat-card-content *ngIf="currentAsk.type == 'code-nps' && lang == 'ES'">
            <mat-label>{{currentAsk.name}}</mat-label><br>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-3" *ngFor="let motivo of motivos">
                        <mat-checkbox [(ngModel)]="motivo.isChecked" (change)="updateCheck(motivo.id)">{{motivo.text}}
                        </mat-checkbox>
                    </div>
                </div>
            </div>

        </mat-card-content>
        <mat-card-content *ngIf="currentAsk.type == 'code-nps' && lang == 'EN'">
            <mat-label>{{currentAsk.name}}</mat-label><br>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-3" *ngFor="let reason of reasons">
                        <mat-checkbox [(ngModel)]="reason.isChecked" (change)="updateCheck(reason.id)">{{reason.text}}
                        </mat-checkbox>
                    </div>
                </div>
            </div>

        </mat-card-content>
        <mat-card-content *ngIf="currentAsk.type == 'idioma1' && lang == 'ES'">
            <mat-label>{{currentAsk.name}}</mat-label><br>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-3">

                    </div>
                    <div class="col-md-3">
                        <h3>Escrito</h3>
                    </div>
                    <div class="col-md-3">
                        <h3>Comprensión Lectora</h3>
                    </div>
                    <div class="col-md-3">
                        <h3>Hablado</h3>
                    </div>
                </div>
                <div class="row" *ngFor="let idioma of idiomas; index as i">

                    <div class="col-md-3">
                        <mat-label>{{idioma.label}}</mat-label>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field class="example-full-width" [(ngModel)]="idiomas[i].write">
                            <!-- (selectionChange)="update(i, $event)" [(ngModel)]="currentAsk.value" -->
                            <mat-select (selectionChange)="write(idioma.label, $event)" [(ngModel)]="idiomas[i].write">
                                <mat-option value='0'>

                                </mat-option>
                                <mat-option value='1'>
                                    A1
                                </mat-option>
                                <mat-option value='2'>
                                    A2
                                </mat-option>
                                <mat-option value='3'>
                                    B1
                                </mat-option>
                                <mat-option value='4'>
                                    B2
                                </mat-option>
                                <mat-option value='5'>
                                    C1
                                </mat-option>
                                <mat-option value='6'>
                                    C2
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field class="example-full-width">
                            <!-- (selectionChange)="update(i, $event)" [(ngModel)]="currentAsk.value" -->
                            <mat-select (selectionChange)="read(idioma.label, $event)" [(ngModel)]="idiomas[i].read">
                                <mat-option value='0'>

                                </mat-option>
                                <mat-option value='1'>
                                    A1
                                </mat-option>
                                <mat-option value='2'>
                                    A2
                                </mat-option>
                                <mat-option value='3'>
                                    B1
                                </mat-option>
                                <mat-option value='4'>
                                    B2
                                </mat-option>
                                <mat-option value='5'>
                                    C1
                                </mat-option>
                                <mat-option value='6'>
                                    C2
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field class="example-full-width" [(ngModel)]="idioma.speak">
                            <!-- (selectionChange)="update(i, $event)" [(ngModel)]="currentAsk.value" -->
                            <mat-select (selectionChange)="speak(idioma.label, $event)" [(ngModel)]="idiomas[i].speak">
                                <mat-option value='0'>

                                </mat-option>
                                <mat-option value='1'>
                                    A1
                                </mat-option>
                                <mat-option value='2'>
                                    A2
                                </mat-option>
                                <mat-option value='3'>
                                    B1
                                </mat-option>
                                <mat-option value='4'>
                                    B2
                                </mat-option>
                                <mat-option value='5'>
                                    C1
                                </mat-option>
                                <mat-option value='6'>
                                    C2
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>

        </mat-card-content>
        <mat-card-content *ngIf="currentAsk.type == 'idioma1' && lang == 'EN'">
            <mat-label>{{currentAsk.name}}</mat-label><br>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-3">

                    </div>
                    <div class="col-md-3">
                        <h3>Writing</h3>
                    </div>
                    <div class="col-md-3">
                        <h3>Reading comprehension</h3>
                    </div>
                    <div class="col-md-3">
                        <h3>Speaking</h3>
                    </div>
                </div>
                <div class="row" *ngFor="let idioma of idiomas; index as i">

                    <div class="col-md-3">
                        <mat-label>{{idioma.label2}}</mat-label>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field class="example-full-width" [(ngModel)]="idiomas[i].write">
                            <!-- (selectionChange)="update(i, $event)" [(ngModel)]="currentAsk.value" -->
                            <mat-select (selectionChange)="write(idioma.label, $event)" [(ngModel)]="idiomas[i].write">
                                <mat-option value='0'>

                                </mat-option>
                                <mat-option value='1'>
                                    A1
                                </mat-option>
                                <mat-option value='2'>
                                    A2
                                </mat-option>
                                <mat-option value='3'>
                                    B1
                                </mat-option>
                                <mat-option value='4'>
                                    B2
                                </mat-option>
                                <mat-option value='5'>
                                    C1
                                </mat-option>
                                <mat-option value='6'>
                                    C2
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field class="example-full-width">
                            <!-- (selectionChange)="update(i, $event)" [(ngModel)]="currentAsk.value" -->
                            <mat-select (selectionChange)="read(idioma.label, $event)" [(ngModel)]="idiomas[i].read">
                                <mat-option value='0'>

                                </mat-option>
                                <mat-option value='1'>
                                    A1
                                </mat-option>
                                <mat-option value='2'>
                                    A2
                                </mat-option>
                                <mat-option value='3'>
                                    B1
                                </mat-option>
                                <mat-option value='4'>
                                    B2
                                </mat-option>
                                <mat-option value='5'>
                                    C1
                                </mat-option>
                                <mat-option value='6'>
                                    C2
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field class="example-full-width" [(ngModel)]="idioma.speak">
                            <!-- (selectionChange)="update(i, $event)" [(ngModel)]="currentAsk.value" -->
                            <mat-select (selectionChange)="speak(idioma.label, $event)" [(ngModel)]="idiomas[i].speak">
                                <mat-option value='0'>

                                </mat-option>
                                <mat-option value='1'>
                                    A1
                                </mat-option>
                                <mat-option value='2'>
                                    A2
                                </mat-option>
                                <mat-option value='3'>
                                    B1
                                </mat-option>
                                <mat-option value='4'>
                                    B2
                                </mat-option>
                                <mat-option value='5'>
                                    C1
                                </mat-option>
                                <mat-option value='6'>
                                    C2
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>

        </mat-card-content>
        <mat-card-content *ngIf="currentAsk.type == 'trueFalse2'">
            <mat-slide-toggle (change)="updateT(i, $event)" [(ngModel)]="currentAsk.value" [value]="currentAsk.value">
                {{currentAsk.name}}
            </mat-slide-toggle>
            <textarea *ngIf="currentAsk.value == true" [(ngModel)]="currentAsk.optional"
                (change)="updateOptional( i , $event)" class="area" matInput>{{currentAsk.optional}}</textarea>
        </mat-card-content>
        <mat-card-content *ngIf="currentAsk.type == 'demografico1' && demografico == true">
            <mat-label>{{currentAsk.name}}</mat-label><br />
            <mat-radio-group aria-label="Select an option" [value]="currentAsk.value" [(ngModel)]="currentAsk.value"
                (change)="update(i, $event)">
                <mat-radio-button value="1">
                    <span *ngIf="lang == 'ES'">Solo ciudades en mi pais</span>
                    <span *ngIf="lang == 'EN'">Only cities in my country</span>
                </mat-radio-button> <br>
                <mat-radio-button value="2">
                    <span *ngIf="lang == 'ES'">Solo países de mi continente</span>
                    <span *ngIf="lang == 'EN'">Only cities from countries on my continent</span>
                </mat-radio-button> <br>
                <mat-radio-button value="3">
                    <span *ngIf="lang == 'ES'">Otros continentes</span>
                    <span *ngIf="lang == 'EN'">Other continents</span>
                </mat-radio-button> <br>
                <mat-radio-button value="4">
                    <span *ngIf="lang == 'ES'">Cualquier ciudad, sin importar el país o continente</span>
                    <span *ngIf="lang == 'EN'">Any city,regardless of the country or continent</span>
                </mat-radio-button> <br>
            </mat-radio-group>
        </mat-card-content>
        <mat-card-content *ngIf="currentAsk.type == 'demografico2' && demografico1 == '3'">
            <mat-label>{{currentAsk.name}}</mat-label> <br>
            <mat-radio-group aria-label="Select an option" [value]="currentAsk.value" [(ngModel)]="currentAsk.value"
                (change)="update(i, $event)">
                <mat-radio-button class="radio-opt" *ngIf="locacion.region != 'AF'" value="AF">África</mat-radio-button>
                <mat-radio-button class="radio-opt" *ngIf="locacion.region != 'AS'" value="AS">Asia</mat-radio-button>
                <mat-radio-button class="radio-opt" *ngIf="locacion.region != 'EU'" value="EU">Europa</mat-radio-button>
                <mat-radio-button class="radio-opt" *ngIf="locacion.region != 'AMN'" value="AMN">Norte América
                </mat-radio-button>
                <mat-radio-button class="radio-opt" *ngIf="locacion.region != 'AMS'" value="AMS">Sur América
                </mat-radio-button>
                <mat-radio-button class="radio-opt" *ngIf="locacion.region != 'OC'" value="OC">Oceanía

                </mat-radio-button>
            </mat-radio-group>
        </mat-card-content>
        <mat-card-content *ngIf="currentAsk.type == 'demografico3' && demografico1 == '2'">
            <mat-label>
                <span *ngIf="lang == 'ES'">Dentro de </span>
                <span *ngIf="lang == 'EN'">Whitin </span>
                <span *ngIf="locacion.region == 'EU'">Europa</span>
                <span *ngIf="locacion.region == 'AMN'">América del Norte</span>
                <span *ngIf="locacion.region == 'AMC'">América Central</span>
                <span *ngIf="locacion.region == 'AMS'">América del Sur</span>
                <span *ngIf="locacion.region == 'AS'">Asia</span>
                <span *ngIf="locacion.region == 'AC'">Oceanía</span>
                <span *ngIf="locacion.region == 'AN'">Antartida</span>
                <span *ngIf="locacion.region == 'AF'">África</span>
                , {{currentAsk.name}}
            </mat-label>

            <br />
            <mat-checkbox class="radio-opt" *ngFor="let city of ciudades" [(ngModel)]="city.isChecked"
                (change)="updateCountries(city._id)">{{city.nombre}}
            </mat-checkbox>
        </mat-card-content>
    </mat-card>
</div>

<div class="next">
    <button (click)="save()" mat-fab color="accent" aria-label="Example icon button with a filter list icon">
        <mat-icon>save</mat-icon>
    </button>
</div>