<div class="langs">
    <div class="flag" (click)="changeFlag('ES')">
        <img src="https://upload.wikimedia.org/wikipedia/commons/8/89/Bandera_de_Espa%C3%B1a.svg" alt="">
    </div>
    <div class="flag" (click)="changeFlag('EN')">
        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Flag_of_the_United_Kingdom_%283-5%29.svg/1200px-Flag_of_the_United_Kingdom_%283-5%29.svg.png" alt="">
    </div>
</div>
<div *ngIf="loading == true" class="loading">
    <mat-spinner class="spinner-custom" [diameter]="55" color="accent"></mat-spinner>
</div>
<div *ngIf="flag == false" class="box-center">
    <mat-card *ngIf="lang =='ES'">
        <mat-card-title class="title">
            Ha ocurrido un error.
        </mat-card-title>
        <mat-card-content>
            <p>Esto puede suceder porque estás ingresando a un enlace caducado o erroneo.</p>
        </mat-card-content>
    </mat-card>
    <mat-card *ngIf="lang =='EN'">
        <mat-card-title class="title">
            An error has occurred.
        </mat-card-title>
        <mat-card-content>
            <p>This can happen because you are entering an expired or wrong link.</p>
        </mat-card-content>
    </mat-card>
</div>
<div class="box-center" *ngIf="flag == true">
    <mat-card>
        <mat-card-title class="title">
            <h2 *ngIf="lang =='ES'">Hola <span *ngIf='survey'> {{survey.employee.name}} {{survey.employee.last_name}}</span></h2>
            <h2 *ngIf="lang =='EN'">HI <span *ngIf='survey'> {{survey.employee.name}} {{survey.employee.last_name}}</span></h2>
        </mat-card-title>
        <mat-card-content *ngIf="lang =='ES'">
            <p>Has recibido la encuesta <b> {{survey.promotion.name}}</b>, haz click para completar esta encuesta, será
                un
                proceso
                rápido y sencillo.</p>
            <div class="alert alert-info" *ngIf="survey.relationEmployee != null">
                <p>Esta encuesta es para que valore como compañero a {{survey.relationEmployee.name}}
                    {{survey.relationEmployee.last_name}} </p>
            </div>
            <button mat-flat-button (click)="startSurvey();" color="accent">Comenzar</button>
        </mat-card-content>
        <mat-card-content *ngIf="lang =='EN'">
            <p>You have received the survey <b> {{survey.promotion.name}}</b>, click to complete this survey, it will be a quick and easy process.</p>
            <div class="alert alert-info" *ngIf="survey.relationEmployee != null">
                <p>This survey is for you to value as a partner {{survey.relationEmployee.name}}
                    {{survey.relationEmployee.last_name}} </p>
            </div>
            <button *ngIf="lang =='ES'" mat-flat-button (click)="startSurvey();" color="accent">Comenzar</button>
            <button *ngIf="lang =='EN'" mat-flat-button (click)="startSurvey();" color="accent">Start</button>
        </mat-card-content>
    </mat-card>
</div>