import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../services/common.service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss'],
})
export class StepComponent implements OnInit {
  step;
  currentAsk;
  total;
  asks;
  lang;
  auxT = 'as';
  condition1 = false;
  condition2 = false;
  condition3 = false;
  condition4 = false;
  motivos = [
    { id: 1, text: 'SUELDO', isChecked: false },
    { id: 2, text: 'HORARIO', isChecked: false },
    { id: 3, text: 'EQUIPO', isChecked: false },
    { id: 4, text: 'PROMOCIONES', isChecked: false },
    { id: 6, text: 'ESTRÉS' },
    { id: 6, text: 'AMBIENTE', isChecked: false },
    { id: 7, text: 'ORGANIZACIÓN', isChecked: false },
    { id: 8, text: 'PROMOCIÓN', isChecked: false },
    { id: 9, text: 'DESGASTE FISÍCO', isChecked: false },
    { id: 10, text: 'FORMACIÓN', isChecked: false },
    { id: 11, text: 'EMPRESA LIDER', isChecked: false },
    { id: 12, text: 'TRATO', isChecked: false },
    { id: 13, text: 'CUALIFICACIÓN PROFESIONAL', isChecked: false },
    { id: 14, text: 'COMUNICACIÓN', isChecked: false },
    { id: 15, text: 'SEGURIDAD', isChecked: false },
    { id: 16, text: 'LEGISLACIÓN', isChecked: false },
    { id: 17, text: 'CERCANÍA', isChecked: false },
  ];

  reasons = [
    { id: 1, text: 'Salary', isChecked: false },
    { id: 2, text: 'Work Hours', isChecked: false },
    { id: 3, text: 'Team', isChecked: false },
    { id: 4, text: 'Promotions', isChecked: false },
    { id: 6, text: 'Stress' },
    { id: 6, text: 'Environment', isChecked: false },
    { id: 7, text: 'Organization', isChecked: false },
    { id: 8, text: 'Promotion', isChecked: false },
    { id: 9, text: 'Physical Wear', isChecked: false },
    { id: 10, text: 'Training', isChecked: false },
    { id: 11, text: 'Leadership ', isChecked: false },
    { id: 12, text: 'Manner', isChecked: false },
    { id: 13, text: 'Professional Qualification', isChecked: false },
    { id: 14, text: 'Communication', isChecked: false },
    { id: 15, text: 'Safety', isChecked: false },
    { id: 16, text: 'Legislation', isChecked: false },
    { id: 17, text: 'Proximity', isChecked: false },
  ];
  idiomas = [
    { label: 'Ingles', label2: 'English', write: '0', read: '0', speak: '0' },
    { label: 'Frances', label2: 'French', write: '0', read: '0', speak: '0' },
    { label: 'Aleman', label2: 'German', write: '0', read: '0', speak: '0' },
    {
      label: 'Portugues',
      label2: 'Portuguese',
      write: '0',
      read: '0',
      speak: '0',
    },
    { label: 'Ruso', label2: 'Russian', write: '0', read: '0', speak: '0' },
    { label: 'Japones', label2: 'Japanese', write: '0', read: '0', speak: '0' },
    {
      label: 'Mandarin',
      label2: 'Mandarin',
      write: '0',
      read: '0',
      speak: '0',
    },
    {
      label: 'Bulgaro',
      label2: 'Bulgarian',
      write: '0',
      read: '0',
      speak: '0',
    },
    { label: 'Polaco', label2: 'Polish', write: '0', read: '0', speak: '0' },
    { label: 'Español', label2: 'Spanish', write: '0', read: '0', speak: '0' },
    { label: 'Koreano', label2: 'korean', write: '0', read: '0', speak: '0' },
    { label: 'Arabe', label2: 'Arabe', write: '0', read: '0', speak: '0' },
  ];
  demografico = false;
  demografico1 = 0;
  demografico2 = 0;
  locacion;
  ciudades;
  selHIjos = 0;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private common: CommonService,
    private _snackBar: MatSnackBar
  ) {}

  async ngOnInit() {
    await this.route.params.subscribe(async (parameter) => {
      console.log(parameter);
      this.step = parseInt(parameter.page);
      let ux = localStorage.getItem('preguntas');
      console.log(ux);
      this.lang = localStorage.getItem('idioma');
      this.asks = JSON.parse(ux);
      let loc = localStorage.getItem('location');
      if (loc != undefined || loc != null) {
        this.locacion = JSON.parse(loc);
      }
      console.log(this.locacion);
    });
  }
  // getAsk() {
  //   let ux = ;
  //   const asks = JSON.parse(ux);
  //   console.log(asks);
  //   this.total = parseInt(asks.length);
  //   this.currentAsk = asks[this.step];
  //   console.log(this.currentAsk);
  // }
  next() {
    this.step = this.step + 1;
    if (this.step < this.total) {
      this.router.navigate(['/step', { page: this.step }]);
    }
  }
  prev() {
    this.step = this.step - 1;
    if (this.step >= 0) {
      this.router.navigate(['/step', { page: this.step }]);
    }
  }
  update(index, field) {
    console.log(index);
    console.log(field);
    if (index == 23) {
      this.demografico1 = field.value;
      this.getDataForDemo(field.value);
    }
    if (index == 24) {
      console.log('se movera a');
      this.demografico2 = field.value;
    }
    this.asks[index].value = field.value;
    localStorage.setItem('preguntas', JSON.stringify(this.asks));
  }
  updateT(index, field) {
    console.log(index);
    console.log(field.checked);
    this.asks[index].value = field.checked;
    if (index == 22) {
      console.log('enes disponibilidad para moverte geográficamente?');
      this.demografico = field.checked;

      this.condition4 = field.checked;
    }
    localStorage.setItem('preguntas', JSON.stringify(this.asks));
  }
  updateTHijos(index, field) {
    console.log(index);
    console.log(field.checked);
    if (field.checked == true) {
      for (let field of this.asks) {
        if (
          field.type == 'hijos' ||
          field.type == 'hijos11' ||
          field.type == 'hijos18' ||
          field.type == 'subtituloEntidades'
        ) {
          field.show = true;
        }
      }
    } else {
      for (let field of this.asks) {
        if (
          field.type == 'hijos' ||
          field.type == 'hijos11' ||
          field.type == 'hijos18' ||
          field.type == 'subtituloEntidades'
        ) {
          field.show = false;
          this.condition1 = false;
          this.condition2 = false;
          this.condition3 = false;
        }
      }
    }
    console.log(this.selHIjos);
    this.asks[index].value = field.checked;
    localStorage.setItem('preguntas', JSON.stringify(this.asks));
  }
  async save() {
    console.log(this.asks);
    for (let ask of this.asks) {
      if (ask.type == 'code-nps') {
        await this.verifyData()
          .then((response) => {
            console.log(response);
            ask.value = response;
          })
          .catch(() => {
            this._snackBar.open('Debe completar todas las preguntas.', 'Ok', {
              duration: 2000,
            });
          });
      }
      if (ask.type == 'idioma1') {
        console.log(ask.value);
        ask.value = JSON.stringify(ask.value);
      }
      if (ask.type == 'demografico3') {
        await this.verifyData2()
          .then((response) => {
            console.log(response);
            ask.value = response;
          })
          .catch(() => {
            this._snackBar.open('Debe completar todas las preguntas.', 'Ok', {
              duration: 2000,
            });
          });
      }
      if (ask.type == 'subtitulo' || ask.type == 'titulo') {
      } else {
        console.log(ask);

        if (ask.value == null && ask.type != 'escala') {
          this._snackBar.open('Debe completar todas las preguntas.', 'Ok', {
            duration: 2000,
          });
          return;
        } else {
        }
      }
    }
    console.log(this.asks);
    this.common.finishSurvey(this.asks).then((res: any) => {
      if (res.ok == true) {
        this.router.navigate(['/thanks']);
      }
    });
  }
  onChange(t) {
    console.log(t);
  }
  toggle(t) {
    console.log(t);
  }
  areaChange(xvalue) {
    console.log(xvalue);
  }
  updateText(i, t) {
    console.log(i, t.target.value);
    this.asks[i].value = t.target.value;
    localStorage.setItem('preguntas', JSON.stringify(this.asks));
  }
  updateOptional(i, t) {
    console.log(i, t.target.value);
    this.asks[i].optional = t.target.value;
    localStorage.setItem('preguntas', JSON.stringify(this.asks));
  }
  updateHijos(i, t) {
    console.log(i, t.target.value);
    console.log(this.asks[i]);
    this.asks[i].value = t.target.value;
    localStorage.setItem('preguntas', JSON.stringify(this.asks));
    if (this.asks[i].type == 'hijos') {
      if (t.target.value > 0) {
        console.log('Habilitar condition 1');
        this.condition1 = true;
      } else {
        this.condition1 = false;
      }
    } else if (this.asks[i].type == 'hijos11') {
      if (t.target.value > 0) {
        console.log('Habilitar condition 2');
        this.condition2 = true;
      } else {
        this.condition2 = false;
      }
    } else if (this.asks[i].type == 'hijos18') {
      if (t.target.value > 0) {
        console.log('Habilitar condition 3');
        this.condition3 = true;
      } else {
        this.condition3 = false;
      }
    }
  }
  select(x) {
    console.log(x);
  }
  async verifyData() {
    let alert = true;
    let selData = '';
    console.log(this.motivos);
    var promise = new Promise((resolve, reject) => {
      for (let cat of this.motivos) {
        if (cat.isChecked == true) {
          alert = false;
          // selData.push(cat.text);
          selData = selData + ' ' + cat.text;
        }
      }
      console.log(selData);
      if (alert == false) {
        resolve(selData);
      } else {
        reject({ alert: true });
      }
    });
    return promise;
  }
  async verifyData2() {
    let alert = true;
    let selData = '';
    console.log(this.ciudades);
    var promise = new Promise((resolve, reject) => {
      for (let cat of this.ciudades) {
        if (cat.isChecked == true) {
          alert = false;
          // selData.push(cat.text);
          selData = selData + ' ' + cat.codigo;
        }
      }
      console.log(selData);
      if (alert == false) {
        resolve(selData);
      } else {
        resolve(selData);
      }
    });
    return promise;
  }
  updateCheck(id) {
    console.log(id);
    for (let item of this.motivos) {
      if (item.id == id) {
        if (item.isChecked == false) {
          item.isChecked = true;
        } else {
          item.isChecked = false;
        }
      }
    }
  }
  async changeFlag(flag) {
    console.log(flag);
    await this.common.getSurvey(flag).then((res: any) => {
      console.log(res);
      if (res.fields.length == 0) {
        if (this.lang == 'ES') {
          this._snackBar.open(
            'No existe traduccion para este cuestionario',
            'Ok',
            {
              duration: 2000,
            }
          );
        } else if (this.lang == 'EN') {
          this._snackBar.open('There is no translation for this survey', 'Ok', {
            duration: 2000,
          });
        }

        return;
      }
      // this.loading = false;
      // this.flag = true;
      // this.survey = res.survey;
      let ar = [];
      let index = 1;
      let xvalue = null;
      for (let field of res.fields) {
        if (
          field.type == 'numero' ||
          field.type == 'escala' ||
          field.type == 'escala-2'
        ) {
          xvalue = 0;
        }
        if (field.type == 'trueFalse') {
          xvalue = false;
        }
        if (field.type == 'textarea') {
          xvalue = '';
        }
        if (field.type == 'code-nps') {
          xvalue = null;
        }
        let json = {
          key: 'p' + index,
          name: field.name,
          type: field.type,
          value: xvalue,
          optional: '',
        };
        ar.push(json);
        index++;
        localStorage.setItem('idioma', field.lang);
      }
      localStorage.setItem('preguntas', JSON.stringify(ar));
      this.ngOnInit();
    });
  }
  read(idioma, event) {
    console.log(idioma, event);
    for (let idi of this.idiomas) {
      if (idi.label == idioma) {
        idi.read = event.value;
      }
    }
    this.updateIdioma();
  }
  write(idioma, event) {
    console.log(idioma, event);
    for (let idi of this.idiomas) {
      if (idi.label == idioma) {
        idi.write = event.value;
      }
    }
    this.updateIdioma();
  }
  speak(idioma, event) {
    console.log(idioma, event);
    for (let idi of this.idiomas) {
      if (idi.label == idioma) {
        idi.speak = event.value;
      }
    }
    this.updateIdioma();
  }
  updateIdioma() {
    let aux = {};
    for (let ask of this.asks) {
      if (ask.type == 'idioma1') {
        for (let idioma of this.idiomas) {
          aux[idioma.label] = idioma;
        }
        console.log(aux);
        ask.value = aux;
      }
    }
    console.log(this.asks);
  }
  getDataForDemo(valor) {
    console.log(valor);
    switch (valor) {
      case '1':
        console.log('Pasar a 9');

        break;
      case '2':
        console.log('buscar ciudades del mismo continente');
        this.common
          .getCountryByRegion(this.locacion.region)
          .then((res: any) => {
            console.log(res);
            for (let city of res.data) {
              city.isChecked = false;
            }
            this.ciudades = res.data;
          });
        break;
      default:
        break;
    }
  }
  updateCountries(id) {
    console.log(id);
    for (let item of this.ciudades) {
      if (item._id == id) {
        if (item.isChecked == false) {
          item.isChecked = true;
        } else {
          item.isChecked = false;
        }
      }
    }
  }
}
