import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../services/common.service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  loading = true;
  flag;
  survey;
  lang;
  ciudades;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private common: CommonService,
    private _snackBar: MatSnackBar
  ) {
    this.lang = localStorage.getItem('idioma');
  }

  async ngOnInit() {
    await this.route.params.subscribe(async (parameter: any) => {
      if (!parameter.id) {
        this.loading = false;
        this.flag = false;
        console.log('no existe parametro id');
      } else {
        console.log('otra cos');
        // this.loading = false;
        localStorage.setItem('BQT.token', parameter.id);
        window.sessionStorage.setItem('BQT.token', parameter.id);
        this.common.getSurvey('ES').then((res: any) => {
          console.log(res);
          this.loading = false;
          this.flag = true;
          this.survey = res.survey;
          let ar = [];
          let index = 1;
          let xvalue = null;
          let show = true;
          for (let field of res.fields) {
            if (field.type == 'numero' || field.type == 'escala') {
              xvalue = 0;
            }
            if (field.type == 'trueFalse') {
              xvalue = false;
            }
            if (field.type == 'trueFalse2') {
              xvalue = false;
            }
            if (field.type == 'trueFalseHijos') {
              xvalue = false;
            }
            if (field.type == 'textarea') {
              xvalue = '';
            }
            if (field.type == 'textareaDemografico') {
              xvalue = '';
            }
            if (field.type == 'code-nps') {
              xvalue = null;
            }
            if (field.type == 'idioma1') {
              xvalue = [];
            }
            if (
              field.type == 'hijos' ||
              field.type == 'hijos11' ||
              field.type == 'hijos18' ||
              field.type == 'subtituloEntidades'
            ) {
              show = false;
            }

            let json = {
              key: 'p' + index,
              name: field.name,
              type: field.type,
              optional: '',
              value: xvalue,
              show: show,
            };
            ar.push(json);
            index++;
          }
          localStorage.setItem('preguntas', JSON.stringify(ar));
          if (res.survey.promotion.type == '6023fe0ed06368540f226a10') {
            console.log("Encuesta de tipo de demografica");
            this.common.getLocation(res.survey.employee.office).then((res:any)=>{
              localStorage.setItem('location', JSON.stringify(res.data));
              this.ciudades = res.data;
            })
          }
        });
      }
    });
  }
  startSurvey() {
    console.log('iniciar encuesta');
    this.router.navigate(['/step', { page: 0 }]);
  }

  changeFlag(flag) {
    console.log(flag);
    this.lang = flag;
    this.common.getSurvey(flag).then((res: any) => {
      console.log(res);

      this.loading = false;
      this.flag = true;
      if (res.fields.length == 0) {
        if (this.lang == 'ES') {
          this._snackBar.open(
            'No existe traduccion para este cuestionario',
            'Ok',
            {
              duration: 2000,
            }
          );
        } else if (this.lang == 'EN') {
          this._snackBar.open('There is no translation for this survey', 'Ok', {
            duration: 2000,
          });
        }

        return;
      }
      this.survey = res.survey;
      let ar = [];
      let index = 1;
      let xvalue = null;
      for (let field of res.fields) {
        if (field.type == 'numero' || field.type == 'escala') {
          xvalue = 0;
        }
        if (field.type == 'trueFalse') {
          xvalue = false;
        }
        if (field.type == 'textarea') {
          xvalue = '';
        }
        if (field.type == 'code-nps') {
          xvalue = null;
        }
        let json = {
          key: 'p' + index,
          name: field.name,
          type: field.type,
          value: xvalue,
        };
        ar.push(json);
        index++;
        localStorage.setItem('idioma', field.lang);
      }
      localStorage.setItem('preguntas', JSON.stringify(ar));
    });
  }
}
